define("shared/components/container-shell/component", ["exports", "ui/utils/platform", "shared/mixins/throttled-resize", "shared/components/container-shell/template", "xterm", "xterm/lib/addons/fit/fit", "jquery"], function (_exports, _platform, _throttledResize, _template, _xterm, fit, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_COMMAND = ['/bin/sh', '-c', 'TERM=xterm-256color; export TERM; [ -x /bin/bash ] && ([ -x /usr/bin/script ] && /usr/bin/script -q -c "/bin/bash" /dev/null || exec /bin/bash) || exec /bin/sh'];
  var statusMap = {
    closed: 'closed',
    connected: 'connected',
    connecting: 'connecting',
    init: 'initializing'
  };

  var _default = Ember.Component.extend(_throttledResize.default, {
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    // URL or instance+cmd
    url: null,
    instance: null,
    command: null,
    alternateLabel: _platform.alternateLabel,
    showProtip: true,
    contenteditable: false,
    error: null,
    socket: null,
    term: null,
    containerName: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._bootstrap();
    },
    didInsertElement: function didInsertElement() {
      this._super();

      Ember.run.next(this, 'exec');
    },
    willDestroyElement: function willDestroyElement() {
      this.disconnect();

      this._super();
    },
    actions: {
      contextMenuHandler: function contextMenuHandler() {
        var _this = this;

        // fix for no paste button in firefox context menu on Windows
        Ember.set(this, 'contenteditable', true);
        setTimeout(function () {
          Ember.set(_this, 'contenteditable', false);
        }, 20);
      }
    },
    containerDidChange: Ember.observer('containerName', function () {
      this.disconnect();
      this.exec();
    }),
    runningContainers: Ember.computed('instance.containers', function () {
      return (Ember.get(this, 'instance.containers') || []).filterBy('canShell', true);
    }),
    status: statusMap.connecting,
    _bootstrap: function _bootstrap() {
      Ember.set(this, 'containerName', Ember.get(this, 'containerName') || Ember.get(this, 'instance.containers.firstObject.name'));
    },
    fit: function fit() {
      var term = Ember.get(this, 'term');
      var socket = Ember.get(this, 'socket');

      if (term && socket) {
        term.fit();
        socket.send("4".concat(AWS.util.base64.encode(JSON.stringify({
          Width: term.cols,
          Height: term.rows
        }))));
      }
    },
    onResize: function onResize() {
      this.fit();
    },
    exec: function exec() {
      var url = Ember.get(this, 'url');

      if (!url) {
        var instance = Ember.get(this, 'instance');
        var clusterId = Ember.get(this, 'scope.currentCluster.id');
        var namespaceId = Ember.get(instance, 'namespaceId');
        var podName = Ember.get(instance, 'name');
        var containerName = Ember.get(this, 'containerName');
        var scheme = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        var command = Ember.get(instance, 'command') || DEFAULT_COMMAND;
        url = "".concat(scheme).concat(window.location.host, "/k8s/clusters/").concat(clusterId, "/api/v1/namespaces/").concat(namespaceId, "/pods/").concat(podName, "/exec");
        url += "?container=".concat(encodeURIComponent(containerName), "&stdout=1&stdin=1&stderr=1&tty=1");

        if (this.isWindows()) {
          command = ['cmd'];
        }

        command.forEach(function (c) {
          url += "&command=".concat(encodeURIComponent(c));
        });
      }

      this.connect(url);
    },
    isWindows: function isWindows() {
      if (Ember.get(this, 'windows') === undefined) {
        var system = Ember.get(this, 'instance.node.info.os.operatingSystem') || '';
        return system.startsWith('Windows');
      } else {
        return Ember.get(this, 'windows');
      }
    },
    connect: function connect(url) {
      var _this2 = this;

      var socket = new WebSocket(url, 'base64.channel.k8s.io');
      Ember.set(this, 'socket', socket);

      socket.onclose = function () {
        var err = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var term = Ember.get(_this2, 'term');

        if (!Ember.get(_this2, 'userClosed')) {
          Ember.set(_this2, 'statusCode', err.code);
        }

        try {
          if (!Ember.get(_this2, 'userClosed') && Ember.get(_this2, 'instance.containers.length') === 1) {
            term.destroy();

            if (_this2.dismiss) {
              _this2.dismiss();
            }
          }

          Ember.set(_this2, 'status', statusMap.closed);
        } catch (e) {}
      };

      socket.onerror = function () {
        var term = Ember.get(_this2, 'term');
        Ember.set(_this2, 'status', statusMap.closed);

        try {
          if (!Ember.get(_this2, 'userClosed') && Ember.get(_this2, 'instance.containers.length') === 1) {
            term.destroy();

            if (_this2.dismiss) {
              _this2.dismiss();
            }
          }
        } catch (e) {}
      };

      socket.onopen = function () {
        Ember.set(_this2, 'status', statusMap.init);

        _xterm.Terminal.applyAddon(fit);

        var term = new _xterm.Terminal({
          cursorBlink: true,
          useStyle: true,
          fontSize: 12
        });
        Ember.set(_this2, 'term', term);
        term.on('data', function (data) {
          socket.send("0".concat(AWS.util.base64.encode(data)));
        });
        term.open((0, _jquery.default)('.shell-body')[0], true);

        _this2.fit();

        term.focus();

        socket.onmessage = function (message) {
          Ember.set(_this2, 'status', statusMap.connected);

          if (_this2.connected) {
            _this2.connected();
          }

          var data = message.data.slice(1);

          switch (message.data[0]) {
            case '1':
            case '2':
            case '3':
              term.write(AWS.util.base64.decode(data).toString());
              break;
          }
        };
      };
    },
    disconnect: function disconnect() {
      Ember.setProperties(this, {
        status: statusMap.closed,
        userClosed: true
      });
      var term = Ember.get(this, 'term');

      if (term) {
        term.destroy();
        Ember.set(this, 'term', null);
      }

      var socket = Ember.get(this, 'socket');

      if (socket) {
        socket.send("0".concat(AWS.util.base64.encode('exit\r\n')));
        socket.close();
        Ember.set(this, 'socket', null);
      }

      if (this.disconnected) {
        this.disconnected();
      }
    }
  });

  _exports.default = _default;
});